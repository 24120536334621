<template>
  <div class="loginContent">
    <header><img src="../../assets/login/logo.png"></header>
    <div class="home">
      <div class="bg">
        <div class="login">
          <div class="title"><span>欢迎注册</span></div>
          <el-form ref="ruleForm" :model="ruleForm" class="login_form" :rules="rules">
            <el-form-item prop="phone">
              <div class="label">
                手机号
              </div>
              <el-input v-model="ruleForm.phone"  maxlength="11" placeholder="请输入您的手机号"></el-input>
            </el-form-item>
            <el-form-item prop="VCode" class="captcha">
              <div class="label">
                验证码
              </div>
              <div>
                <el-input v-model="ruleForm.VCode" placeholder="请输入短信验证码" autocomplete="off" v-if="countdown" class="get_input">
                  <el-button slot="append" type="primary" class="getCaptcha" @click="getCaptchas" :disabled="canGet" :loading="codeLoading">获取短信验证码</el-button>
                </el-input>
                <el-input v-model="ruleForm.VCode" placeholder="请输入短信验证码" autocomplete="off" v-else class="countdown">
                  <el-button slot="append" class="deactivation" style="background-color:#e6edf5">{{second}}秒后重新获取</el-button>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password" class="pwd" style="margin-bottom: 0;">
              <div class="label">
                密码
              </div>
              <el-input :type="changeType(this.ruleForm.password,this.show)" v-model="ruleForm.password" autocomplete="new-password" placeholder="请输入密码" :class="{error_input:isError}" maxlength="20"></el-input>
              <i :class="[show?'iconfont iconicon-28':'iconfont iconicon-27']" style="color:#409EFF;" @click="show=!show" v-if="ruleForm.password&&ruleForm.password.length>0"></i>
<!--              <span class="tip">8-16位,同时包含数字和字母</span>-->
            </el-form-item>
            <div :class="{tip:true,error:isError}" style="margin-top:0;height: 1.55rem;">
              {{errorMsg}}
            </div>
            <el-form-item prop="confirmPassword" class="pwd">
              <div class="label">
                确认密码
              </div>
              <el-input :type="changeType(this.ruleForm.confirmPassword,this.cshow)" v-model="ruleForm.confirmPassword" autocomplete="new-password" placeholder="请输入确认密码" maxlength="20"></el-input>
              <i :class="[cshow?'iconfont iconicon-28':'iconfont iconicon-27']" style="color:#409EFF;" @click="cshow=!cshow" v-if="ruleForm.confirmPassword&&ruleForm.confirmPassword.length>0"></i>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="button" @click="submitForm('ruleForm')" :disabled="canLogin" :loading='isLoading'>注册</el-button>
            </el-form-item>
            <div class="register">
              <span class="hasThm">已有账号？</span>
              <el-link type="primary" href="/login">立即登录</el-link>
            </div>
          </el-form>
        </div>
         <div class="left">
        <img src="@/assets/login/banner.webp" alt="">
      </div>
      </div>
      <el-link href="/" class="logo" :underline="false"></el-link>
      <msg :icon="icon" :message="message" :color='color' :promptVisible="promptVisible" :title="title" :column="true" :row="false" top="22vh" class="employ_msg" :showClose="true" />
    </div>
    <div class="app-footer">
      <p class="mt60">地址：武汉市东湖开发区华中科技大学科技园天喻楼 | 邮政编码：430223 | 电话：027-87920300</p>
      <p class="mb60">传真：027-87920306 | E-Mail：webmaster@whty.com.cn | 鄂ICP备05002327 | 鄂公安网安备42018502000263号</p>
    </div>
  </div>
</template>

<script>
  import { mapGetters, } from 'vuex'
  import { getRegister, getCaptcha, isExist } from '@/api/user'
  export default {
    data() {
      var telValidate = (rule, value, callback) => {
        const reg = /^1[3|4|5|7|8|9][0-9]{9}$/
        this.canGet = true
        if (!value || value.length > 11 || !reg.test(value)) {
          callback(new Error('请输入有效的手机号'))
        } else {
          isExist(this.ruleForm.phone).then(res =>{
            if(res.success){
              callback(new Error('该手机号已注册'))
            }else{
              this.canGet = false
              callback()
            }
          }).catch(err => {
          //  einvAlert.error("提示",err)
          })
          }
        }
      var validatePass = (rule, value, callback) => {
        var reg = /^(?![^A-z]+$)(?!\\D+$)[A-z\\d]{8,16}$/
        if (value === '') {
          this.errorMsg = '请输入密码';
          this.isError = true;
        }else if(!reg.test(value)){
          this.errorMsg = '必须包含数字、字母，长度至少为8位且不超过16位';
          this.isError = true;
        }else if(this.ruleForm.confirmPassword !== ''){
          this.$refs.ruleForm.validateField('confirmPassword')
          this.isError = false;
          callback();
        } else {
          this.isError = false;
          this.errorMsg ='8-16位,同时包含数字、字母'
          callback();
        }
      };
      var validateConfirmPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致'));
        } else {
          callback();
        }
      };
      return {
        second: 60,
        countdown: true,
        promptVisible : false,
        icon : 'iconfont iconicon1-17',
        color: '#35C4B1',
        message : '修改提示',
        title: '',
        canLogin: false,
        // 是否能获取验证码
        canGet: false,
        isError: false,
        errorMsg: '8-16位,同时包含数字和字母',
        ruleForm: {
          password: '',
          confirmPassword: '',
          phone: '',
          VCode: '',
        },
        codeSrc: '',
        rules: {
          phone: [
            { required: true, validator: telValidate, trigger: 'change' }
          ],
          password: [
            // { min: 8, max: 20, message: '密码长度至少为8位且不超过20位' },
            // { required: true, message: '请输入密码', trigger: 'blur' },
            // { pattern: '(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}', message: '密码必须包含数字、字母和符号', trigger: 'blur' },
            { required: true, validator: validatePass, trigger: 'blur' }
          ],

          confirmPassword: [
            { required: true, validator: validateConfirmPass, trigger: 'blur' }
          ],
          VCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        checked: false,
        show: true,
        cshow: true,
        type: 'password',
        num: 5,
        isLoading: false,
        codeLoading: false
      }
    },
    computed: {
      ...mapGetters([
        'register'
      ])
    },
    mounted() {
      if (localStorage.getItem('oldTime')) {
        this.oldTime = localStorage.getItem('oldTime');
        var nowTime = new Date().getTime() - this.oldTime;
        /*if (nowTime < 60000) {
          this.second = 60 - parseInt(nowTime / 1000);
          this.countdown = false;
          this.lastSecond();
        }*/
      }
    },
    methods: {
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.isLoading = true
            getRegister(
              this.ruleForm
            ).then(res => {
              if (res.success) {
 /*               this.title = '注册'
                this.message = "恭喜您注册成功！"
                this.promptVisible = true
                setTimeout(() => {
                  this.promptVisible = false
                }, 1000)*/
                einvAlert.success("提示",'注册成功','恭喜您注册成功！')
                //einvAlert.success("提示",'恭喜您注册成功')
                setTimeout(() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  this.$router.replace('/login')
                }, 2000)
                /*einvAlert.success("提示",'恭喜您注册成功！')
                this.$router.replace('/login');*/
                this.ruleForm = {
                  password: '',
                  confirmPassword: '',
                  phone: '',
                  VCode: ''
                }
              } else {
                // einvAlert.error("提示",res.msg)
/*                this.title = '注册'
                this.message = res.msg
                this.promptVisible = true
                setTimeout(() => {
                  this.promptVisible = false
                }, 1000)*/
                einvAlert.error('注册失败',res.msg)
              }
            }).catch(err => {
              // einvAlert.error("提示",err)
/*              this.title = '注册'
              this.message = err
              this.promptVisible = true
              setTimeout(() => {
                this.promptVisible = false
              }, 1000)*/
              einvAlert.error('注册失败',err)
            })
            this.isLoading = false
          } else {
            this.isLoading = false
            console.log('error submit!!')
            return false
          }
        })
      },
      // 获取短信验证码
      getCaptchas() {
        this.codeLoading = true
        if (this.ruleForm.phone) {
          getCaptcha({
            phone: this.ruleForm.phone,
            type : 1,
          }).then(res => {
            if (res.success) {
              this.codeLoading = false
/*              this.title= "验证码"
              this.message = "验证码发送成功"
              this.promptVisible = true
              setTimeout(() => {
                this.promptVisible = false
              }, 1000)*/
              einvAlert.success('验证码','验证码发送成功')
              // einvAlert.success("提示",'验证码发送成功')
              var date = new Date();
              var oldTime = date.getTime();
              localStorage.setItem('oldTime', oldTime);
              this.countdown = false;
              this.second = 60;
              this.lastSecond();
            } else {
              this.codeLoading = false
              // einvAlert.error("提示",res.msg)
              einvAlert.error('验证码',res.msg)
              /*this.title= "验证码"
              this.message = res.msg
              this.promptVisible = true
              setTimeout(() => {
                this.promptVisible = false
              }, 1000)*/
            }
          }).catch(err => {
            this.codeLoading = false;
            // einvAlert.error("提示",err)
            einvAlert.error('验证码',err)
/*            this.title= "验证码"
            this.message = err
            this.promptVisible = true
            setTimeout(() => {
              this.promptVisible = false
            }, 1000)*/
          })
        }else{
/*          this.title= "验证码"
          this.message = '请输入手机号'
          this.promptVisible = true
          setTimeout(() => {
            this.promptVisible = false
            this.codeLoading = false;
          }, 1000)*/
          einvAlert.error('验证码','请输入手机号')
          this.codeLoading = false;
          /*setTimeout(()=>{
            einvAlert.error("提示",'请输入手机号')
            this.codeLoading = false;
          },500)*/
        }
      },
      // 倒计时
      lastSecond() {
        var clearSecond = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            this.countdown = true
            clearInterval(clearSecond)
          }
        }, 1000);
      },
      //是否显示密码
      changeType(password, show) {
        if (password.length == 0) {
          show = true
        }
        if (show == true) {
          return 'password'
        } else {
          return 'text'
        }
      },
    }
  }
</script>
<style lang="less" scoped>
.loginContent {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
header{
  opacity: 1;
  width: 100%;
  height: 48px;
  line-height: 42px;
  background: #ffffff;
  border-bottom: 0.15rem solid #F1F3FF;
  box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.20);
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  text-indent: 30px;
  padding-top: 8px;
  img {
    height: 24px;
  }
}
.home {
  background: #fff;
  height: 85%;
  width: 100%;
  background-size: 100% 100%;
  position: relative;
  .bg {
    position: absolute;
    top: 75%;
    left: 45%;
    width: 960px;
    height: 57rem;
    transform: translate(-50%, -50%);
    .left {
      position: absolute;
      width: 31.25rem;
      height: 100%;
      top: 50px;
      background-position: center;
      left: 0;
      img {
        width: 31.25rem;
        height: 27.08rem;
      }
    }
    .login {
      position: absolute;
      left: 34rem;
      top: 0;
      bottom: 0;
      padding: 36px 46px;
      width: 24.6rem;
      height: 35.5rem;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      border-radius: 20px;
      box-shadow: 0px 6px 18px 2px rgba(55,99,169,0.10);
      .title {
        text-align: center;
        margin-bottom: 15px;
        font-size: 28px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        span{
          border-bottom: 0.18rem solid #409EFF;
          padding-bottom: 7px;
        }
      }
      .reset{
        font-size: 16px;
        color: #409EFF;
      }
      .login_form {
        .captcha .countdown /deep/.el-input-group__append {
          padding: 0;
          background-color: #aaaaaa;
          width: 100px;
          .deactivation {
            margin: 0;
            width: 177px;
            background-color: #aaaaaa !important;
            border: 1px solid #aaaaaa;
          }
        }
        /deep/.el-input-group__append {
          border: 0 solid #dcdfe6;
          padding: 0;
          width: 100px;
          color: #fff;
          vertical-align: unset;
          left: 2px;
          .getCaptcha {
            margin: 0;
            border-radius: 0;
            width: 177px;
            position: relative;
            top: 0;
          }
          .el-button--medium {
            // padding: 0;
            background-color: #66b1ff;
            border: 1px solid #66b1ff;
          }
        }
        .pwd i {
          position: absolute;
          right: 14px;
          top: 74%;
          transform: translateY(-50%);
        }
        .el-form-item {
          margin-bottom: 26px;
          position: relative;
          .error {
            position: absolute;
            bottom: -40px;
            color: #e80000;
          }
          .el-button {
            width: 100%;
            height: 46px;
            font-size: 16px;
          }
          .iconxianshi {
            color: #409EFF !important;
          }
          .el-form-item__content {
            margin-left: 0 !important;
            .codeImg {
              height: 50px;
              margin-left: 5%;
              width: 95%;
              border: 1px solid #e0e0e0;
              border-radius: 9px;
              cursor: pointer;
            }
          }
          /deep/ .el-input__inner {
            color: #222;
            height: 48px;
          }
          .label {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
          }
          .textTips{
            cursor: pointer;
            font-size: 16px;
            i{
              color: #409EFF;
              font-style: normal;
              text-decoration: underline;
            }
          }
        }
        .el-checkbox {
          margin-bottom: 24px;
          font-size: 14px;
          .el-checkbox__input,
          .el-checkbox__label {
            vertical-align: top;
          }
        }
        .el-icon-view:before {
          content: "";
        }
        .register {
          float: right;
          font-size: 18px;
          .hasThm {
            color: #999999;
          }
          .el-link {
            vertical-align: bottom;
            font-size: 18px;
            color: #409EFF;
          }
          /deep/.el-link.is-underline:hover:after {
            border-color: #409EFF;
          }
        }
      }
    }
  }
}
.app-footer {
  position: absolute;
  height: 12%;
  width: 100%;
  // padding: 20px;
  bottom: 0;
  overflow: hidden;
  background: #F1F3FF;
  p {
    padding: 0;
    margin: 0;
    color: #333333;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    line-height: 35px;
    text-align: center;
  }
  .mt60 {
    margin-top: 24px;
  }
}
.tip{
  color: #999999;
  font-size: 10px;
}
.error{
  color: #f56c6c;
}
.error_input{
  /deep/ input {
    border-color: #f56c6c!important;
  }
}
</style>
